import React from "react";
import YouTube from "react-youtube";

export default ({
  videoID,
}) => {
  return (
    <YouTube videoId={videoID}/>
  );
};
